var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "id": "result"
    }
  }, [_vm.result ? _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.result)
    }
  }) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }